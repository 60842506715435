import { useHeaderStore } from 'store/store'

import PreviewOffButton from '../parts/PreviewOffButton'
import Footer from './Footer'
import Header from './Header'
import Meta from './Meta'

export default function Layout({ preview, loading, page, settings, children }) {
  
  const setIsTransparent = useHeaderStore(state => state.setIsTransparent)
  
  if (preview && loading) {
    return <div>Loading preview...</div>
  }

  const addHeight = page.slug || page._id.includes("errorPage");

  const isFrontPage = !page.slug && !page._id.includes("errorPage");
  setIsTransparent(isFrontPage)
  
  return (
    <>
      <Meta page={page} settings={settings} />
      {preview && <PreviewOffButton slug={page.slug} />}
      <Header page={page} settings={settings} />
      {addHeight && <div className="h-[115px]"></div>}
      <main>{children}</main>
      <Footer page={page} settings={settings} />
    </>
  )
}
