import { PortableTextComponents } from '@portabletext/react'
import cx from 'classnames'
import dynamic from 'next/dynamic'
import Image from 'next/image'

import CustomLink from '../components/parts/LinkInBlockContent'
import { urlForImage } from './sanity.image'

//const YouTubeEmbed = dynamic(() => import('../components/youTubeEmbed'))

export const portableTextComponents: PortableTextComponents = {
  block: {
    normal: ({ children }) => (
      <p className="min-h-paragraph mb-3">{children}</p>
    ),
    h1: ({ children }) => <h1 className="mb-3 text-4xl">{children}</h1>,
    h2: ({ children }) => <h2 className="mb-3 text-3xl">{children}</h2>,
    h3: ({ children }) => <h3 className="mb-3 text-2xl">{children}</h3>,
    h4: ({ children }) => <h4 className="mb-3">{children}</h4>,
    h5: ({ children }) => <h5 className="mb-3">{children}</h5>,
    pretitle: ({ children }) => (
      <div className="mb-3 text-xl font-bold uppercase">{children}</div>
    ),
    small: ({ children }) => (
      <div className="text-small mb-3 font-medium">{children}</div>
    ),
    medium: ({ children }) => (
      <div className="text-medium mb-3 font-medium">{children}</div>
    ),
    large: ({ children }) => (
      <div className="text-large mb-3 font-medium">{children}</div>
    ),
  },
  list: {
    bullet: ({ children }) => (
      <ul className="list-inside list-disc">{children}</ul>
    ),
    number: ({ children }) => (
      <ol className="list-inside list-decimal">{children}</ol>
    ),
  },
  types: {
    image: ({ value }) => {
      if (value.asset) {
        const { asset, alt, fullWidth } = value
        const { lqip } = asset?.metadata
        const { width, height } = asset?.metadata.dimensions
        return (
          <div className={cx('mx-auto mb-4')}>
            <Image
              src={urlForImage(asset).width(1000).url()}
              width={width}
              height={height}
              placeholder="blur"
              blurDataURL={lqip}
              className="transition-all duration-700"
              alt={alt || asset.originalFilename}
            />
          </div>
        )
      }
    },
    // youtube: ({ value }) => {
    //     return <YouTubeEmbed data={value} />
    // }
  },
  marks: {
    center: ({ children }) => (
      <span className="block text-center">{children}</span>
    ),
    link: ({ children, value }) => {
      return <CustomLink link={{ value, children }} />
    },
  },
}
