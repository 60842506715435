export const SiteLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="55"
      height="69"
      viewBox="44.578 43.141 9.098 11.51"
    >
      <path d="M46.763 50.077c-1.223 0-1.722-.428-1.794-.428-.036 0-.19.19-.285.333h-.083l-.024-1.865h.095c.226.665.736 1.33 1.164 1.556.214.107.594.249.926.249.915 0 1.556-.558 1.556-1.402 0-.701-.475-1.01-1.081-1.235-.606-.226-.82-.273-1.378-.487-.641-.249-1.235-.891-1.235-1.699 0-1.342 1.057-1.96 2.245-1.96 1.128 0 1.58.451 1.615.451s.19-.19.285-.333h.107v1.734h-.107c-.309-.831-.926-1.699-1.9-1.699-.784 0-1.402.463-1.402 1.318 0 .653.451 1.045 1.081 1.271.511.202.855.285 1.342.463.843.309 1.342.915 1.342 1.794-.022 1.203-1.043 1.939-2.469 1.939zM53.439 47.88H49.65v-.06c.19-.024.416-.095.523-.226.071-.095.107-.38.107-.641 0-.214.012-.796.012-1.223v-1.14c0-.273 0-.57-.083-.677-.107-.131-.309-.202-.511-.226v-.059h1.889v.059c-.214.024-.606.095-.618.523-.012.546-.012.867-.012 1.449v1.354c0 .249 0 .511.071.618.059.083.404.154.725.154.131 0 .594-.012.867-.083.63-.166.808-.582.998-1.057l.059.012-.238 1.223z" />
      <path d="M51.087 48.652c-.083.083-.238.273-.511.938a163.669 163.669 0 0 0-1.651 4.169l-.428.891h-.107l-1.912-4.87a7.778 7.778 0 0 0-.416-.915.857.857 0 0 0-.796-.487v-.095h2.625v.083c-.119.012-.689.083-.689.451 0 .309 1.283 3.492 1.687 4.514.107-.261.677-1.71 1.307-3.397.238-.641.285-.938.285-1.069 0-.344-.535-.451-.843-.487v-.083h2.031v.095a1.085 1.085 0 0 0-.582.262z" />
    </svg>
  )
}

export const SiteLogoWhite = () => {
  return (
    <svg
      data-name="Group 2849"
      width="55"
      height="69"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52.453 66.407"
    >
      <path
        data-name="Path 1109"
        d="M52.113 20.218c-1.125 2.725-2.146 5.11-5.758 6.1a22.921 22.921 0 0 1-4.973.511c-1.806 0-3.815-.442-4.155-.917-.409-.615-.409-2.08-.444-3.543-.033-3.1-.033-4.737-.033-7.835 0-3.373 0-5.212.1-8.381.068-2.453 2.352-2.827 3.578-3v-.34H29.562v.34a4.609 4.609 0 0 1 2.964 1.293c.511.615.511 2.316.511 3.919v6.609c0 2.487-.033 5.791-.069 7.051-.033 1.465-.2 3.134-.645 3.68a5.187 5.187 0 0 1-3 1.3v.34h21.87l1.26-7.018Z"
        fill="#fff"
      />
      <path
        data-name="Path 1110"
        d="M29.273 30.178c1.763.2 4.836.856 4.836 2.822 0 .754-.253 2.517-1.612 6.2a979.658 979.658 0 0 1-7.559 19.6c-1.506-3.865-5.16-12.962-7.538-19.344 5.635-1.333 9.35-5.11 9.35-10.6 0-5.079-2.865-8.54-7.729-10.322-2.81-1.028-4.81-1.567-7.727-2.7-3.621-1.352-6.216-3.567-6.216-7.348 0-4.919 3.567-7.623 8.107-7.623 5.621 0 9.187 4.973 10.972 9.783h.593v-10h-.589a6.641 6.641 0 0 1-1.621 1.945c-.217 0-2.81-2.593-9.3-2.593C6.376-.002.269 3.565.269 11.298c0 4.647 3.406 8.322 7.133 9.781 3.243 1.243 4.432 1.515 7.946 2.81 3.458 1.3 6.214 3.08 6.214 7.136a7.558 7.558 0 0 1-4.616 7.2 33.29 33.29 0 0 1-1.735-5.479c0-2.066 3.326-2.47 3.983-2.569v-.506H4.024v.555a5 5 0 0 1 4.585 2.822 52.343 52.343 0 0 1 2.368 5.29l.26.662a14.247 14.247 0 0 1-4-1.333c-2.487-1.3-5.406-5.136-6.7-8.972h-.539l.161 10.754h.487c.6-.808 1.458-1.891 1.676-1.891a19.573 19.573 0 0 0 9.3 2.411c3.155 8 6.287 15.964 10.393 26.437h.605l2.47-5.139c2.921-7.66 6.8-17.535 9.523-24.038 1.612-3.881 2.52-4.987 2.973-5.441a6.424 6.424 0 0 1 3.427-1.562v-.555h-11.74Z"
        fill="#fff"
      />
    </svg>
  )
}

export const ChevronDown = ({ className = 'w-4 h-4' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  )
}

export const MenuBars = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-8 w-8"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.75 9h16.5m-16.5 6.75h16.5"
      />
    </svg>
  )
}

export const XMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="h-8 w-8"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  )
}

export const Facebook = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="21"
      viewBox="0 0 10.961 21.257"
    >
      <path
        d="M3.184 21.257v-9.508H0V7.971h3.184V4.995C3.184 1.76 5.161 0 8.046 0a26.734 26.734 0 0 1 2.914.149v3.38h-2c-1.569 0-1.872.747-1.872 1.839v2.6h3.541l-.486 3.778H7.087v9.507"
        fill="#fff"
      />
    </svg>
  )
}

export const Instagram = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21.268 21.264"
    >
      <path
        d="M10.637 5.18a5.452 5.452 0 1 0 5.452 5.452 5.443 5.443 0 0 0-5.452-5.452Zm0 9a3.544 3.544 0 1 1 3.544-3.544 3.551 3.551 0 0 1-3.544 3.539Zm6.946-9.219a1.272 1.272 0 1 1-1.272-1.272 1.269 1.269 0 0 1 1.272 1.268Zm3.611 1.291a6.293 6.293 0 0 0-1.719-4.46A6.334 6.334 0 0 0 15.021.075c-1.756-.1-7.018-.1-8.773 0a6.325 6.325 0 0 0-4.455 1.713A6.313 6.313 0 0 0 .075 6.243c-.1 1.756-.1 7.018 0 8.773a6.293 6.293 0 0 0 1.717 4.459 6.342 6.342 0 0 0 4.455 1.718c1.756.1 7.018.1 8.773 0a6.293 6.293 0 0 0 4.455-1.718 6.334 6.334 0 0 0 1.718-4.455c.1-1.76.1-7.017.001-8.772ZM18.926 16.9a3.588 3.588 0 0 1-2.021 2.021c-1.4.555-4.721.427-6.268.427s-4.873.123-6.268-.427A3.588 3.588 0 0 1 2.348 16.9c-.555-1.4-.427-4.721-.427-6.268s-.123-4.873.427-6.268a3.588 3.588 0 0 1 2.021-2.021c1.4-.555 4.721-.427 6.268-.427s4.873-.123 6.268.427a3.588 3.588 0 0 1 2.021 2.021c.555 1.4.427 4.721.427 6.268s.128 4.873-.427 6.268Z"
        fill="#fff"
      />
    </svg>
  )
}

export const ArrowUp = () => {
  return (
    <svg
      viewBox="0 0 16 23"
      width="15"
      height="22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.707.293a1 1 0 0 0-1.414 0L.929 6.657A1 1 0 0 0 2.343 8.07L8 2.414l5.657 5.657a1 1 0 1 0 1.414-1.414L8.707.293ZM9 23V1H7v22h2Z"
        fill="#000"
      />
    </svg>
  )
}

export const MapPin = ({className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
      <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
    </svg>
  )
}

export const Close = ({ className = "w-6 h-6" }) => {
  return (<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={className}>
    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
  </svg>
  )
}


export const SwiperArrow = () => {
  return (
    <svg width="16" height="28" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-3">
      <path d="M1 1L8 8L1 15" stroke="black" />
    </svg>
  )
}
export const Globe = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418" />
    </svg>

  )
}

export const NorwayFlag = ({className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 640 480" className={className}>
      <path fill="#ef2b2d" d="M0 0h640v480H0z" />
      <path fill="#fff" d="M180 0h120v480H180z" />
      <path fill="#fff" d="M0 180h640v120H0z" />
      <path fill="#002868" d="M210 0h60v480h-60z" />
      <path fill="#002868" d="M0 210h640v60H0z" />
    </svg>
  )
}
export const SwedenFlag = ({className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} height="20" viewBox="0 0 640 480">
      <defs>
        <clipPath id="a">
          <path fillOpacity=".67" d="M-53.421 0h682.67v512h-682.67z" />
        </clipPath>
      </defs>
      <g clipPath="url(#a)" transform="translate(50.082) scale(.9375)">
        <g fillRule="evenodd" strokeWidth="1pt">
          <path fill="#006aa7" d="M-121.103.302h256V205.1h-256zM-121.103 307.178h256v204.8h-256z" />
          <path fill="#fecc00" d="M-121.103 204.984h256v102.4h-256z" />
          <path fill="#fecc00" d="M133.843.01h102.4v511.997h-102.4z" />
          <path fill="#fecc00" d="M232.995 205.013h460.798v102.4H232.995z" />
          <path fill="#006aa7" d="M236.155 307.208h460.797v204.799H236.155zM236.155.302h460.797V205.1H236.155z" />
        </g>
      </g>
    </svg>
  )
}
export const DenmarkFlag = ({className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} height="20" viewBox="0 0 640 480">
      <path fill="#c60c30" d="M0 0h640.1v480H0z" />
      <path fill="#fff" d="M205.714 0h68.57v480h-68.57z" />
      <path fill="#fff" d="M0 205.714h640.1v68.57H0z" />
    </svg>
  )
}
export const GBFlag = ({className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 30" className={className} height="20">
      <clipPath id="s">
        <path d="M0,0 v30 h60 v-30 z" />
      </clipPath>
      <clipPath id="t">
        <path d="M30,15 h30 v15 z v15 h-30 z h-30 v-15 z v-15 h30 z" />
      </clipPath>
      <g clipPath="url(#s)">
        <path d="M0,0 v30 h60 v-30 z" fill="#012169" />
        <path d="M0,0 L60,30 M60,0 L0,30" stroke="#fff" strokeWidth="6" />
        <path d="M0,0 L60,30 M60,0 L0,30" clipPath="url(#t)" stroke="#C8102E" strokeWidth="4" />
        <path d="M30,0 v30 M0,15 h60" stroke="#fff" strokeWidth="10" />
        <path d="M30,0 v30 M0,15 h60" stroke="#C8102E" strokeWidth="6" />
      </g>
    </svg>
  )
}

export const Magnifyer = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 absolute top-7 right-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
    </svg>
  )
}

export const CloseFullscreen = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
      <path d="M18 1L1 18" stroke="black" />
      <path d="M1 1L18 18" stroke="black" />
    </svg>
  )
}

export const InfoIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 min-w-[24px] min-h-[24px]">
      <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
    </svg>

  )
}