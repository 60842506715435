import { PortableText } from '@portabletext/react'
import Container from 'components/parts/Container'
import {
  ArrowUp,
  SiteLogoWhite,
} from 'components/parts/Icons'
import { portableTextComponents } from 'lib/portableTextComponent'
import NextImage from "next/image";
import Link from 'next/link'
import useTranslation from "next-translate/useTranslation";

export default function Footer({
  page,
  settings,
}: {
  page: any
  settings: any
}) {
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const { t } = useTranslation("common");
  const footer = settings.footer || {};
  
  return (
    <footer className="site-footer bg-footer font-light font-normal text-white text-light text-14">
      <Container>
        <div className="footer-outer">
          <div className="flex flex-wrap justify-between">
            <Link href="/" aria-label="home" className="flex-10 footer-logo lg:mt-[15px]">
              <SiteLogoWhite />
            </Link>
            <div className="flex-90">
              <div className="flex flex-wrap">
                <div className="flex-33 footer-left flex-start mb-4 h-full justify-start">
                  <h3>{t('customerService')}</h3>
                  <div className="footer-content">
                    <PortableText value={footer.body} components={portableTextComponents} />
                  </div>
                </div>
                <div className="flex-33 footer-left flex-start mb-4 h-full justify-start">
                  <h3>Slettvoll</h3>
                  <div className="footer-content flex flex-col">
                    {footer.links && footer.links.map(link => (
                      <Link aria-label={link.title} key={link._key} href={`/${link.slug}`}>{link.title}</Link>
                    ))}
                  </div>
                </div>
                <div className="flex-33 footer-left flex-start mb-4 h-full justify-start">
                  <h3>{t('followUs')}</h3>
                  <div className="footer-content flex">
                    {footer.someLinks && footer.someLinks.map(item => (
                      <Link key={item._key} href={item.link} className='mr-[10px] mt-[9px]' aria-label={`go to Slettvoll ${item?.link || "some"}`}>
                        <NextImage
                          src={item?.image?.asset?.url}
                          width={64}
                          height={64}
                          quality={75}
                          alt={item?.image?.alt || "social media logo"}
                          className="h-full w-full"
                        />
                        {/* <img src={item?.image?.asset?.url} alt={item.image.alt || ""} /> */}
                      </Link>
                    ))}
                  </div>
                  <div className="footer-copyright pt-4 text-10">
                    <span>© COPYRIGHT 2023</span>
                  </div>
                </div>
                <button className="tothetop" onClick={scrollToTop}>
                  <div className="totop flex justify-center">
                    <ArrowUp />
                  </div>
                  <span>{t('toTop')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  )
}
