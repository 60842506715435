import cx from 'classnames'
import Container from 'components/parts/Container'
import { SiteLogo, SiteLogoWhite } from 'components/parts/Icons'
import LanguageSwitcher from 'components/parts/LanguageSwitcher'
import { languagesEnabled } from 'language.config'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'
import React, { useEffect, useState } from 'react'
import { useHeaderStore } from 'store/store'

import Navigation from './Navigation'

export default function Header({
  page,
  settings,
}: {
  page: any
  settings: any
}) {
  const [show, setShow] = useState(true)
  const [scrollDir, setScrollDir] = useState("scrolling up");

  const isTransparent = useHeaderStore(state => state.isTransparent)
  
  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.scrollY;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.scrollY;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY && lastScrollY > 100 ? "scrolling down" : "scrolling up");
      lastScrollY = scrollY > 0 ? scrollY : 0;
      
      ticking = false;


    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", onScroll);
    
    if (scrollDir === "scrolling up") {
      setShow(true);
    } else {
      setShow(false);
    }
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollDir]);



  return (
    <header
      className={cx(
        'fixed top-0 z-[9999] flex h-[115px] w-full flex-row items-center transition duration-500 ease-in-out',
        { '-translate-y-full': !show },
        { 'bg-transparent text-white': isTransparent },
        { 'bg-white': !isTransparent }
      )}
    >
      <Container className="flex flex-row items-center">
        {/* <Navigation navigation={settings?.navigation} /> */}
        <div className='w-1/3 flex flex-col md:flex-row'>
          <Link href="https://slettvoll.com" aria-label="go to https://slettvoll.com" className="mr-12 text-14 font-normal font-light uppercase leading-24 tracking-1.26 after:scale-0 after:transform after:border-b after:border-solid after:transition after:duration-300 after:ease-in-out hover:scale-100">
            Slettvoll.com
          </Link>
          {/* <Link href="/" aria-label="Home" className="text-14 font-normal font-light uppercase leading-24 tracking-1.26 after:scale-0 after:transform after:border-b after:border-solid after:transition after:duration-300 after:ease-in-out hover:scale-100">
            Outlet
          </Link> */}
        </div>
        <Link href="/" aria-label="go to homepage" className="flex w-1/3 justify-center">
          {!isTransparent ? <SiteLogo /> : <SiteLogoWhite />}
        </Link>
        <div className='w-1/3 flex justify-end'>
          {languagesEnabled && <LanguageSwitcher page={page} />}
        </div>
      </Container>
    </header>
  )
}
