import cx from 'classnames'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useState } from "react";

import { DenmarkFlag, GBFlag, Globe, NorwayFlag, SwedenFlag } from './Icons';

export default function LanguageSwitcher({ page }) {
  const { locale, asPath } = useRouter();
  
  const [langOpen, setLangOpen] = useState(false);
  const { t } = useTranslation('common')

  const path_no =
    page?.translated?.lang == 'no' && asPath != '/'
      ? '/' + page.translated?.slug
      : '/'
  const path_en =
    page?.translated?.lang == 'en' && asPath != '/'
      ? '/' + page.translated?.slug
      : '/'
  const path_se =
    page?.translated?.lang == 'se' && asPath != '/'
      ? '/' + page.translated?.slug
      : '/'
  const path_dk =
    page?.translated?.lang == 'dk' && asPath != '/'
      ? '/' + page.translated?.slug
      : '/'

  return (
    <div className='relative font-light leading-24 tracking-1.26'>
      <button className="px-2 flex uppercase" onClick={() => setLangOpen(!langOpen)}>
        <Globe />&nbsp;{locale}
      </button>
      <ul className={cx('absolute border top-8 left-3 bg-white py-4 px-6 text-black rounded transition duration-500 ease-in-out min-w-[180px]', { "opacity-100 visible": langOpen }, { "opacity-0 invisible": !langOpen })}>
        <li className='whitespace-nowrap pb-2'>{t('selectLanguage')}</li>
        <Link aria-label='norsk' onClick={() => setLangOpen(false)} href={path_no} locale="no" className="flex items-center pt-1">
          <div className='rounded-full h-5 w-5 overflow-hidden'>
            <NorwayFlag className="ml-[50%] transform -translate-x-[50%]" />
          </div>
          <div className='pl-2'>Norsk</div>
        </Link>
        <Link aria-label='svenska' onClick={() => setLangOpen(false)} href={path_se} locale="se" className="flex items-center pt-1">
          <div className='rounded-full h-5 w-5 overflow-hidden'>
            <SwedenFlag className={"ml-[50%] transform -translate-x-[50%]"} />
          </div>
          <div className='pl-2'>Svenska</div>
        </Link>
        <Link aria-label='dansk' onClick={() => setLangOpen(false)} href={path_dk} locale="dk" className="flex items-center pt-1">
          <div className='rounded-full h-5 w-5 overflow-hidden'>
            <DenmarkFlag className={"ml-[50%] transform -translate-x-[50%]"} />
          </div>
          <div className='pl-2'>Dansk</div>
        </Link>
        <Link aria-label='english' onClick={() => setLangOpen(false)} href={path_en} locale="en" className="flex items-center pt-1">
          <div className='rounded-full h-5 w-5 overflow-hidden'>
            <GBFlag className={"ml-[50%] transform -translate-x-[50%]"} />
          </div>
          <div className='pl-2'>English</div>
        </Link>
      </ul>
    </div>
  )
}
