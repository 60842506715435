import { create } from 'zustand'


interface iHeaderState {
    isTransparent: Boolean
    setIsTransparent: (state: Boolean) => void
}
export const useHeaderStore = create<iHeaderState>(set => ({
    isTransparent: true,
    setIsTransparent: (state) => set({ isTransparent: state })
}))
