export default function PreviewOffButton({ slug }: { slug: string }) {
  return (
    <div className="fixed z-[99999] left-1/2 -translate-x-1/2 bg-yellow-500 shadow-md px-6 py-2 text-sm text-center">
      <a href={`/api/exit-preview?slug=${slug || '/'}`}>
        <div className="uppercase">Live preview</div>
        <div>Klikk her for å slå av preview</div>
      </a>
    </div>
  )
}
